<template>
  <view-item title="工单查询">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="工单金额" name="amount" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="amount.query.data" :method="amount.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="amount.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态" >
                  <select-maintain
                    v-model="amount.query.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => (params.paths = ['SVCSTAT'])
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="首单">
                  <en-select v-model="amount.query.data.newVehicleOnly" class="w-full" clearable>
                    <en-option label="是" :value="true"></en-option>
                    <en-option label="否" :value="false"></en-option>
                  </en-select>
                </en-form-item>
                <en-form-item label="维修项目">
                  <en-input v-model="amount.query.data.maintenance"></en-input>
                </en-form-item>
                <en-form-item label="配件名称">
                  <en-input v-model="amount.query.data.goodsName"></en-input>
                </en-form-item>
                <en-form-item label="配件编码">
                  <en-input v-model="amount.query.data.goodsSerialNo"></en-input>
                </en-form-item>
                <en-form-item label="OE号">
                  <en-input v-model="amount.query.data.oem"></en-input>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQAM"
                    :height="height"
                    :data="amount.data"
                    :method="amount.get"
                    :config="amount.config"
                    :loading="amount.loading"
                    pagination
                    :paging="amount.paging"
                    show-summary
                    :summary="amount.summary.data"
                  >
                    <template #OPERATION>
                      <en-button type="primary" link>打印</en-button>
                    </template>

                    <template #SETTLEMENT_PROGRESS="{ row }: { row: EnocloudStatementDefinitions['ServiceQueryDto'] }">
                      {{ row.settlementProgress?.message }}
                    </template>
                    <template #SERIAL_NO="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                      <en-button type="primary" link @click="amount.serialNo.click(row)">
                        {{ row.serialNo }}
                      </en-button>
                    </template>
                    <template #INVOICE_IMAGE="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                      <en-button v-if="row.invoiceImage" @click="amount.click(row)" text>查看</en-button>
                      <span v-else>-</span>
                    </template>
                    <template #VEHICLE_PHOTO="{ row }: { row: EnocloudInventoryDefinitions['PurchaseGoodsDto'] }">
                      <en-button v-if="row.serviceVehicleImgUrls.length > 0" @click="amount.accidentBook.click(row)" text>查看</en-button>
                      <span v-else>无</span>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="工单项目" name="maintenance" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="maintenance.query.data" :method="maintenance.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="maintenance.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="maintenance.query.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => (params.paths = ['SVCSTAT']),
                      convert(data) {
                        return data.filter((item) => item.code !== 'DC')
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>

                <en-form-item label="配件名称">
                  <en-input v-model="maintenance.query.data.goodsName"></en-input>
                </en-form-item>
                <en-form-item label="配件编码">
                  <en-input v-model="maintenance.query.data.goodsSerialNo"></en-input>
                </en-form-item>
                <en-form-item label="OE号">
                  <en-input v-model="maintenance.query.data.oem"></en-input>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQMT"
                    :height="height"
                    :data="maintenance.data"
                    :method="maintenance.get"
                    :summary="maintenance.summary.data"
                    show-summary
                    :loading="maintenance.loading"
                    :paging="maintenance.paging"
                    pagination
                    :config="maintenance.config"
                  >
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="工单配件" name="goods" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="goods.query.data" :method="goods.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="goods.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="goods.query.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => (params.paths = ['SVCSTAT']),
                      convert(data) {
                        return data.filter((item) => item.code !== 'DC')
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQGD"
                    :height="height"
                    :data="goods.data"
                    :method="goods.get"
                    :summary="goods.summary.data"
                    show-summary
                    :loading="goods.loading"
                    :paging="goods.paging"
                    pagination
                    :config="goods.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="内返项目" name="internal" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="internal.query.data" :method="internal.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="internal.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="internal.query.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => (params.paths = ['SVCSTAT']),
                      convert(data) {
                        return data.filter((item) => item.code !== 'DC')
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQRWI"
                    :height="height"
                    :data="internal.data"
                    :method="internal.get"
                    :summary="internal.summary.data"
                    show-summary
                    :loading="internal.loading"
                    :paging="internal.paging"
                    pagination
                    :config="internal.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="外返项目" name="external" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="external.query.data" :method="external.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="external.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="external.query.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => (params.paths = ['SVCSTAT']),
                      convert(data) {
                        return data.filter((item) => item.code !== 'DC')
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQRWE"
                    :height="height"
                    :data="external.data"
                    :method="external.get"
                    :summary="external.summary.data"
                    show-summary
                    :loading="external.loading"
                    :paging="external.paging"
                    pagination
                    :config="external.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="其他费用" name="other" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="other.query.data" :method="other.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="other.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="other.query.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => (params.paths = ['SVCSTAT']),
                      convert(data) {
                        return data.filter((item) => item.code !== 'DC')
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQOTH"
                    :height="height"
                    :data="other.data"
                    :method="other.get"
                    show-summary
                    :loading="other.loading"
                    :paging="other.paging"
                    pagination
                    :config="other.config"
                    :summary-method="other.summaryMethod"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="废弃工单" name="cancel" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="cancel.query.data" :method="cancel.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="cancel.query.data.branchIds"
                    :ajax="{
                      action: 'GET /enocloud/common/branch',
                      params: (params) => {
                        params.payload = { pagingEnabled: false }
                      }
                    }"
                    :props="{ label: 'shortName', value: 'id' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQCCL"
                    :height="height"
                    :data="cancel.data"
                    :method="cancel.get"
                    :loading="cancel.loading"
                    :paging="cancel.paging"
                    pagination
                    :config="cancel.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="事故理赔" name="accident" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="accident.query.data" :method="accident.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="accident.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="accident.query.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => (params.paths = ['SVCSTAT']),
                      convert(data) {
                        return data.filter((item) => item.code !== 'DC')
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQACD"
                    :height="height"
                    :data="accident.data"
                    :method="accident.get"
                    :summary="accident.summary.data"
                    show-summary
                    :loading="accident.loading"
                    :paging="accident.paging"
                    pagination
                    :config="accident.config"
                  >
                    <template #CLAIM_DOCUMENT="{ row }: { row: EnocloudQueryDefinitions['ServiceAccidentSettlementQueryDto'] }">
                      <en-button v-if="row.damageOrderImgUrl" @click="accident.click(row)" text>查看</en-button>
                      <span v-else>无</span>
                    </template>
                    <template #ACCIDENT_DOCUMENT="{ row }: { row: EnocloudQueryDefinitions['ServiceAccidentSettlementQueryDto'] }">
                      <en-button v-if="row.accidentBookImgUrl" @click="accident.accidentBook.click(row)" text>查看</en-button>
                      <span v-else>无</span>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="附表金额" name="fake" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="fake.query.data" :method="fake.get">
                <en-form-item label="门店">
                  <select-maintain
                    v-model="fake.query.data.branchIds"
                    :ajax="{ action: 'GET /enocloud/common/branch', params: (params) => (params.payload = { pagingEnabled: false }) }"
                    :props="{ label: 'shortName', value: 'id' }"
                    multiple
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="单据日期">
                  <en-date-picker
                    v-model:start="fake.query.data.preparedStartDate"
                    v-model:end="fake.query.data.preparedEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQFAK"
                    :height="height"
                    :data="fake.data"
                    :method="fake.get"
                    :summary="fake.summary.data"
                    show-summary
                    :loading="fake.loading"
                    :paging="fake.paging"
                    pagination
                    :config="fake.config"
                  >
                    <template #VEHICLE_PHOTO="{ row }: { row: EnocloudQueryDefinitions['ServiceQueryDto'] }">
                      <en-button v-if="row.serviceVehicleImgUrls.length > 0" @click="fake.img.click(row)" text>查看</en-button>
                      <span v-else>无</span>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
    <en-dialog v-model="inDetail.visible" title="工单详情">
      <flex-box>
        <template #default="{ height }">
          <table-dynamic
            code="SVCMOVFD"
            :height="height"
            :data="inDetail.table.data"
            :method="inDetail.table.get"
            show-summary
            :loading="inDetail.table.loading"
            :paging="inDetail.table.paging"
            pagination
            :config="inDetail.table.config"
          >
            <template #PRICE="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
              <span>{{ row.price }}</span>
            </template>
          </table-dynamic>
        </template>
      </flex-box>
    </en-dialog>
    <en-dialog v-model="accident.visible" title="工单详情">
      <img :src="accident.imgSrc" :zoom-rate="1.2" :preview-src-list="srcList" :initial-index="4" fit="cover" style="width: 700px; height: 700px" />
    </en-dialog>

    <en-dialog v-model="accident.accidentBook.visible" title="工单详情">
      <en-image :src="accident.accidentBook.imgSrc" :zoom-rate="1.2" :initial-index="4" fit="cover" />
    </en-dialog>

    <en-dialog v-model="amount.visible" title="工单详情">
      <img :src="amount.imgSrc" :zoom-rate="1.2" :initial-index="4" fit="cover" style="width: 700px; height: 700px" />
    </en-dialog>
    <en-dialog v-model="fake.visible">
      <img :src="fake.imgSrc" :zoom-rate="1.2" :initial-index="4" fit="cover" style="width: 700px; height: 700px" />
    </en-dialog>
    <en-dialog v-model="amount.accidentBook.visible" title="工单详情">
      <template v-for="(item, index) in amount.accidentBook.imgSrc">
        <en-image
          :src="item"
          :initial-index="index"
          :preview-src-list="amount.accidentBook.imgSrc"
          fit="conver"
          class="flex gap-4 flex-wrap w-30 h-30 mr-10"
        />
      </template>
    </en-dialog>
  </view-item>
</template>

<script lang="ts">
import type { SummaryMethod, TableColumnCtx, TableInstance, ColumnStyle, CellStyle } from 'element-plus'
import { calculator } from '@enocloud/utils'


export default factory({

  page: true,
  
  config: {
    children: {
      inDetail: {
        visible: false,
        children: {
          form: {
            data: {
              customerId: '',
              startDate: '',
              endDate: '',
              id: '',
              maintenances: [],
              serviceGoods: [],
              formDataCount: {
                maintenance: {
                  data: [],
                  canDiscount: [], // 可以打折的项目
                  forPay: [], // 收费的项目
                  fee: 0, // 折前费用
                  discount: 0, // 打折费用
                  free: 0, // free费用
                  final: 0, // 所有折后费用
                  ratio: 0,
                  profit: 0, // 毛利

                  maxDiscountRation: 0,
                  outsource: 0, // 服务外包费用
                  guarantee: 0, // 三包费用
                  repare: 0 // 索赔返修费用
                },
                material: {
                  data: [],
                  canDiscount: [],
                  forPay: [],
                  discount: 0,
                  free: 0,
                  final: 0,
                  fee: 0,
                  ratio: 0,

                  guarantee: 0,
                  repare: 0
                },
                otherCost: {
                  data: [],
                  fee: 0,
                  info: ''
                },
                total: {
                  fee: 0,
                  discount: 0,
                  final: 0,
                  repare: 0
                },
                discount: 0, // 工单工单减免
                serviceFee: 0, // 维修总费用
                couponInstancesAmount: 0, // 卡券优惠
                amount: 0, // 工单合计
                receivableAmount: 0, // 应收款
                autoEraseAmount: 0
              }
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/service/:serviceId',
                data: 'array',
                loading: true,
                params(params) {
                  params.paths = [this.inDetail.form.data.id]
                }
              }
            }
          }
        }
      },

      operation: {
        children: {
          export: {
            ajax: {
              amount: {
                action: 'GET /enocloud/statement/service/amount/export',
                params(params) {
                  params.payload = this.amount.query.data
                }
              },
              
              maintenance: {
                action: 'GET /enocloud/query/service/maintenance/export',
                params(params) {
                  params.payload = this.maintenance.query.data
                }
              },
              goods: {
                action: 'GET /enocloud/query/service/goods/export',
                params(params) {
                  params.payload = this.goods.query.data
                }
              },
              internal: {
                action: 'GET /enocloud/query/service/internal/export',
                params(params) {
                  params.payload = this.internal.query.data
                }
              },
              external: {
                action: 'GET /enocloud/query/service/external/export',
                params(params) {
                  params.payload = this.external.query.data
                }
              },
              other: {
                action: 'GET /enocloud/query/service/other/export',
                params(params) {
                  params.payload = this.other.query.data
                }
              },
              cancel: {
                action: 'GET /enocloud/query/service/export/cancel',
                params(params) {
                  params.payload = this.cancel.query.data
                }
              },
              accident: {
                action: 'GET /enocloud/query/service/export/accident',
                params(params) {
                  params.payload = this.accident.query.data
                }
              },
              fake: {
                action: 'GET /enocloud/service/export',
                params(params) {
                  params.payload = this.fake.query.data
                }
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'amount':
                 this.ajax('GET /enocloud/statement/service/amount/export', this.amount.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'maintenance':
                this.ajax('GET /enocloud/query/service/maintenance/export', this.maintenance.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'goods':
                this.ajax('GET /enocloud/query/service/goods/export', this.goods.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'internal':
                this.ajax('GET /enocloud/query/service/internal/export', this.aminternalount.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'external':
                this.ajax('GET /enocloud/query/service/external/export', this.external.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'other':
                this.ajax('GET /enocloud/query/service/other/export', this.other.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'cancel':
                this.ajax('GET /enocloud/query/service/cancel/export', this.cancel.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'accident':
                this.ajax('GET /enocloud/query/service/accident/export', this.accident.$ajaxParams)
                 this.store.openDownload()
                  break
                case 'fake':
                this.ajax('GET /enocloud/query/service/maintenance/export', this.amount.$ajaxParams)
                 this.store.openDownload()
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'amount'
      },
      amount: {
        serialNo: {
          click(row) {
            this.inDetail.form.data.id = row?.amount?.id
            this.inDetail.visible = true
          }
        },
        click(row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
          this.amount.imgSrc = row.invoiceImage
          this.amount.visible = true
        },
        accidentBook: {
          click(row: EnocloudInventoryDefinitions['PurchaseGoodsDto']) {
         
            this.amount.accidentBook.imgSrc = row.serviceVehicleImgUrls
            this.amount.accidentBook.visible = true
          }
        },

        query: {
          data: {
            status: '',
            maintenance: '',
            goodsName: '',
            goodsSerialNo: '',
            oem: '',
            settlementProgress:'',
            branchIds: [],
            advisorId:''
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/statement/query/service/amount',
            summary: 'GET /enocloud/statement/query/service/amount/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
           

              params.payload = Object.assign(this.amount.query.data, this.amount.$ajaxParams)

            }
          }
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          ASSIGNEE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'assigneeName',
                props: {
                  ajax: { action: 'GET /enocloud/common/workingteam/member' },
                  props: { label: 'user.name', value: 'user.name' },
                  allowCreate: true
                }
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  multiple: true,
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'customerCellphone'
              }
            }
          },
          SERIAL_NO: {
            header: { filter: { type: 'text', field: 'serialNo' } }
          },

          CUSTOMER_SALESMAN_NAME: {
            header: { filter: { type: 'text', field: 'customer.salesmanName' } }
          },
          VEHICLE_SPEC: {
            header: { filter: { type: 'text', field: 'customer.vehicleSpec' } }
          },
          VEHICLE_VIN: {
            header: { filter: { type: 'text', field: 'customer.vin' } }
          },
          INVOICE_TITLE: {
            header: { filter: { type: 'text', field: 'invoiceTitle' } }
          },
          INVOICE_NO: {
            header: { filter: { type: 'text', field: 'invoiceNo' } }
          },
          RECEIPT_COMMENT: {
            header: { filter: { type: 'text', field: 'receiptComment' } }
          },
          CREDIT_DATETIME: {
            header: { filter: { type: 'date', field: ['creditStartDate', 'creditEndDate'], props: { type: 'daterange' } } }
          },
          ENTER_DATETIME: {
            header: { filter: { type: 'date', field: ['enterStartDate', 'enterEndDate'], props: { type: 'daterange' } } }
          },
          SETTLEMENT_DATETIME: {
            header: { filter: { type: 'date', field: ['settlementStartDate', 'settlementEndDate'], props: { type: 'daterange' } } }
          },
          INVOICE_DATE: {
            header: { filter: { type: 'date', field: ['invoiceDateStart', 'invoiceDateEnd'], props: { type: 'daterange' } } }
          },
          SETTLE_DATETIME: {
            header: { filter: { type: 'date', field: ['settleStartDate', 'settleEndDate'], props: { type: 'daterange' } } }
          },
          ANNUAL_INSPECTION_DATE: {
            header: {
              filter: { type: 'date', field: ['vehicle.annualInspectionDateStart', 'vehicle.annualInspectionDateEnd'], props: { type: 'daterange' } }
            }
          },
          NEXT_MAINTENANCE_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['vehicle.commercialInsuranceEndDateStart', 'vehicle.commercialInsuranceEndDateEnd'],
                props: { type: 'daterange' }
              }
            }
          },
          COMPULSORY_INSURANCE_END_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['vehicle.compulsoryInsuranceEndDateStart', 'vehicle.compulsoryInsuranceEndDateEnd'],
                props: { type: 'daterange' }
              }
            }
          },
          SALESMAN_NAME: {
            header: { filter: { type: 'text', field: 'salesmanName' } }
          },
          SENDER: {
            header: { filter: { type: 'text', field: 'sender' } }
          },
          SENDER_TELEPHONE: {
            header: { filter: { type: 'text', field: 'senderTelephone' } }
          },
          COMMENT: {
            header: { filter: { type: 'text', field: 'comment' } }
          },
          SETTLEMENT_COMMENT: {
            header: { filter: { type: 'text', field: 'settlementComment' } }
          },
          PLATE_NO: {
            header: { filter: { type: 'text', field: 'plateNo' } }
          },
          RECEIPT_BY: {
            header: { filter: { type: 'text', field: 'receiptBy' } }
          },
          SETTLEMENT_AUDIT_COMMENT: {
            header: { filter: { type: 'text', field: 'settlementAuditComment' } }
          },
          CUSTOMER_SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customer.serviceAdvisorNames',
                props: {
                  allowCreate: true,
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, rsexp: 'name' })
                  },
                  props: { label: 'name', value: 'name' },
                  remote: true,
                  multiple: true
                }
              }
            }
          },

          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INVOICE_IMAGE: {
            header: {
              filter: {
                type: 'select',
                field: 'hasInvoiceImage',
                props: {
                  options: [
                    { message: '有', code: 'Y' },
                    { message: '无', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CLKTYPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          CREDIT: {
            header: {
              filter: {
                type: 'select',
                field: 'credited',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FLAG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INVOICE_TYPE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => {
                      params.paths = ['INVCTP']
                    }
                  },
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          WORKING_TEAM: {
            header: {
              filter: {
                type: 'select',
                field: 'workingTeamName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          WORKING_TEAM_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'workingTeamName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'customer.salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CLKTYPE']
                    }
                  },
                  remote: true,
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgress',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['STLMTPRG']
                    }
                  },
                  allowCreate: true,
                  remote: true,
                  multiple: true,
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'vehicle.fuelCategory',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FLCTGY']
                    }
                  },

                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        }
      },
      maintenance: {
        query: {
          data: {
            status: 'PD',
            branchIds: [],
            newVehicleOnly: false,
            maintenance: '',
            goodsName: '',
            goodsSerialNo: '',
            oem: '',
            advisorFirst: 1
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/maintenance',
            summary: 'GET /enocloud/query/service/maintenance/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.maintenance.query.data
            }
          }
        },
        config: {
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: { filter: { type: 'text', field: 'serialNo' } }
          },

          CUSTOMER_SALESMAN_NAME: {
            header: { filter: { type: 'text', field: 'customer.salesmanName' } }
          },
          VEHICLE_SPEC: {
            header: { filter: { type: 'text', field: 'vehicleSpec' } }
          },
          VEHICLE_VIN: {
            header: { filter: { type: 'text', field: 'vin' } }
          },
          INVOICE_TITLE: {
            header: { filter: { type: 'text', field: 'invoiceTitle' } }
          },
          INVOICE_NO: {
            header: { filter: { type: 'text', field: 'invoiceNo' } }
          },
          RECEIPT_COMMENT: {
            header: { filter: { type: 'text', field: 'receiptComment' } }
          },
          CREDIT_DATETIME: {
            header: { filter: { type: 'date', field: ['creditStartDate', 'creditEndDate'], props: { type: 'daterange' } } }
          },
          ENTER_DATETIME: {
            header: { filter: { type: 'date', field: ['enterStartDate', 'enterEndDate'], props: { type: 'daterange' } } }
          },
          SETTLEMENT_DATETIME: {
            header: { filter: { type: 'date', field: ['settlementStartDate', 'settlementEndDate'], props: { type: 'daterange' } } }
          },
          COMPLETE_DATETIME: {
            header: { filter: { type: 'date', field: ['completionStartDate', 'completionEndDate'], props: { type: 'daterange' } } }
          },
          INVOICE_DATE: {
            header: { filter: { type: 'date', field: ['invoiceDateStart', 'invoiceDateEnd'], props: { type: 'daterange' } } }
          },
          SETTLE_DATETIME: {
            header: { filter: { type: 'date', field: ['settleStartDate', 'settleEndDate'], props: { type: 'daterange' } } }
          },
          ANNUAL_INSPECTION_DATE: {
            header: {
              filter: { type: 'date', field: ['vehicle.annualInspectionDateStart', 'vehicle.annualInspectionDateEnd'], props: { type: 'daterange' } }
            }
          },
          NEXT_MAINTENANCE_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['nextMaintenanceStartDate', 'nextMaintenanceEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMPULSORY_INSURANCE_END_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['vehicle.compulsoryInsuranceEndDateStart', 'vehicle.compulsoryInsuranceEndDateEnd'],
                props: { type: 'daterange' }
              }
            }
          },
          SALESMAN_NAME: {
            header: { filter: { type: 'text', field: 'salesmanName' } }
          },
          SENDER: {
            header: { filter: { type: 'text', field: 'sender' } }
          },
          SENDER_TELEPHONE: {
            header: { filter: { type: 'text', field: 'senderTelephone' } }
          },
          COMMENT: {
            header: { filter: { type: 'text', field: 'comment' } }
          },
          SETTLEMENT_COMMENT: {
            header: { filter: { type: 'text', field: 'settlementComment' } }
          },
          PLATE_NO: {
            header: { filter: { type: 'text', field: 'plateNo' } }
          },
          RECEIPT_BY: {
            header: { filter: { type: 'text', field: 'receiptBy' } }
          },
          MAINTENANCE_NAME: {
            header: { filter: { type: 'text', field: 'maintenanceName' } }
          },
          CELLPHONE: {
            header: { filter: { type: 'text', field: 'cellphone' } }
          },
          SETTLEMENT_AUDIT_COMMENT: {
            header: { filter: { type: 'text', field: 'settlementAuditComment' } }
          },
          CUSTOMER_SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerServiceAdvisorNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          FINAL_INSPECTOR: {
            header: {
              filter: {
                type: 'select',
                field: 'service.qualityInspectorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: '' }
                }
              }
            }
          },
          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'service.settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'service.accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INVOICE_IMAGE: {
            header: {
              filter: {
                type: 'select',
                field: 'hasInvoiceImage',
                props: {
                  options: [
                    { message: '有', code: 'Y' },
                    { message: '无', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CLKTYPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },

          REWORK_INTERNAL: {
            header: {
              filter: {
                type: 'select',
                field: 'internalRework',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FLAG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INFLATED: {
            header: {
              filter: {
                type: 'select',
                field: 'inflated',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['INFLTTPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          CREDIT: {
            header: {
              filter: {
                type: 'select',
                field: 'credited',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FLAG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INVOICE_TYPE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => {
                      params.paths = ['INVCTP']
                    }
                  },
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          WORKING_TEAM_ASSIGNEE: {
            header: {
              filter: {
                type: 'select',
                field: 'assigneeName',
                props: {
                  ajax: { action: 'GET /enocloud/common/workingteam/member' },
                  props: { label: 'user.name', value: 'user.name' },
                  allowCreate: true
                }
              }
            }
          },
          WORKING_TEAM_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'workingTeamName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CHARGING_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'chargingMethodCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CHGMTD']
                      params.payload = { excludes: ['TNM', 'RMN', 'OTS'] }
                    }
                  },

                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          VALUATION_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'valuationMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['VLMTD']
                    }
                  },

                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          CUSTOMER_SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'customer.salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CLKTYPE']
                    }
                  },
                  allowCreate: true,
                  remote: true,
                  multiple: true,
                  props: { label: 'message', value: '' }
                }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgress',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['STLMTPRG']
                    }
                  },
                  allowCreate: true,
                  remote: true,
                  multiple: true,
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'service.vehicle.fuelCategory',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FLCTGY']
                    }
                  },

                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        }
      },
      goods: {
        query: {
          data: {
            status: 'PD',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/goods',
            summary: 'GET /enocloud/query/service/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.goods.query.data
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['count', 'amount', 'totalCost', 'amountAfterDiscount', 'goodsProfit'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: { filter: { type: 'text', field: 'serialNo' } }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CLKTYPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FLCTGY']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },

          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          CUSTOMER_SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerServiceAdvisorNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CONTACT_CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },

          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'service.accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          VEHICLE_VIN: {
            header: {
              filter: {
                type: 'text',
                field: 'vin'
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSupplierName'
              }
            }
          },
          BATCH_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsBatchNo'
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMPLETE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['completionStartDate', 'completionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settleStartDate', 'settleEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          FINAL_INSPECTOR: {
            header: {
              filter: {
                type: 'select',
                field: 'service.qualityInspectorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsOem'
              }
            }
          },
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_BRAND: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsBrand'
              }
            }
          },
          GOODS_PLACE_OF_PRODUCTION: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsPlaceOfProduction'
              }
            }
          },
          GOODS_VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsVehicleSpec'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsModel'
              }
            }
          },
          WAREHOUSE_NAMES: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          WORKING_TEAM: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.workingTeamName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          WORKING_TEAM_ASSIGNEE: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.assigneeName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['SVCSTLMTD'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          PICKED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'pickedBy'
              }
            }
          },
          PICKED_WORKING_TEAM: {
            header: {
              filter: {
                type: 'select',
                field: 'pickedWorkingTeamId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SALESMAN: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanId',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user'
                  },
                  allowCreate: true,
                  multiple: true
                }
              }
            }
          },
          INFLATED: {
            header: {
              filter: {
                type: 'select',
                field: 'inflated',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['INFLTTPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'service.settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true
                }
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceComment'
              }
            }
          },
          SETTLEMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementComment'
              }
            }
          },

          GOODS_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'goodsType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['GOODSTP'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          SENDER: {
            header: {
              filter: {
                type: 'text',
                field: 'sender'
              }
            }
          },
          SENDER_TELEPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'senderTelephone'
              }
            }
          },
          SETTLEMENT_AUDIT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementAuditComment'
              }
            }
          }
        }
      },
      internal: {
        query: {
          data: {
            status: 'PD',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/internal',
            summary: 'GET /enocloud/query/service/internal/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.internal.query.data
            }
          }
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.service.vehicle.fuelCategory',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          VEHICLE_VIN: {
            header: {
              filter: {
                type: 'text',
                field: 'vin'
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMPLETE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['actualCompletionStartDate', 'actualCompletionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SETTLE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settleStartDate', 'settleEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          MAINTENANCE_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'maintenanceName'
              }
            }
          },
          WORKING_TEAM: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.workingTeamName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  multiple: true
                }
              }
            }
          },
          WORKING_TEAM_ASSIGNEE: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.assigneeName',
                props: {
                  ajax: { action: 'GET /enocloud/common/workingteam/member' },
                  props: { label: 'user.name', value: 'user.name' },
                  allowCreate: true
                }
              }
            }
          },
          FINAL_INSPECTOR: {
            header: {
              filter: {
                type: 'select',
                field: 'qualityInspectorName',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user'
                  },
                  allowCreate: true,
                  multiple: true
                }
              }
            }
          },
          FINAL_INSPECT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['qualityDateStart', 'qualityDateEnd'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.service.settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceComment'
              }
            }
          },
          SETTLEMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementComment'
              }
            }
          },
          VALUATION_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'valuationMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['VLMTD'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          REWORK_REASON: {
            header: {
              filter: {
                type: 'text',
                field: 'reason'
              }
            }
          },
          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          }
        }
      },
      external: {
        query: {
          data: {
            status: 'PD',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/external',
            summary: 'GET /enocloud/query/service/external/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.external.query.data
            }
          }
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.service.vehicle.fuelCategory',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          VEHICLE_VIN: {
            header: {
              filter: {
                type: 'text',
                field: 'vin'
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMPLETE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['actualCompletionStartDate', 'actualCompletionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SETTLE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settleStartDate', 'settleEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          FINAL_INSPECTOR: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.service.qualityInspectorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          MAINTENANCE_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'maintenanceName'
              }
            }
          },
          VALUATION_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'valuationMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['VLMTD']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          WORKING_TEAM: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.workingTeamName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          WORKING_TEAM_ASSIGNEE: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.assigneeName',

                props: {
                  ajax: { action: 'GET /enocloud/common/service/category' },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceMaintenance.service.settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          SERVICE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceComment'
              }
            }
          },
          REWORK_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'reworkComment'
              }
            }
          },
          REWORK_SERVICE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'externalSerialNo'
              }
            }
          },
          REWORK_SERVICE_MAINTENANCE: {
            header: {
              filter: {
                type: 'text',
                field: 'maintenanceName'
              }
            }
          },
          REWORK_REASON: {
            header: {
              filter: {
                type: 'select',
                field: 'reworkTypeCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['RWKTP'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SETTLEMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementComment'
              }
            }
          },
          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          }
        }
      },
      other: {
        query: {
          data: {
            status: 'PD',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/other',
            summary: 'GET /enocloud/query/service/other/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.other.query.data
            }
          }
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'service.vehicle.fuelCategory',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/service/category' },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          VEHICLE_VIN: {
            header: {
              filter: {
                type: 'text',
                field: 'vin'
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settleStartDate', 'settleEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMPLETE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['completionStartDate', 'completionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'name'
              }
            }
          },
          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'service.settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user'
                  },
                  allowCreate: true,
                  multiple: true
                }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'service.accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERVICE_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceComment'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          SETTLEMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementComment'
              }
            }
          },
          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['count', 'amount'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        }
      },
      cancel: {
        query: {
          data: {
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/cancel',

            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: 'DC' }
            }
          }
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'vehicle.fuelCategory',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/service/category' },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                props: {
                  allowCreate: true,
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, rsexp: 'name' })
                  },
                  props: { label: 'name', value: 'name' },
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'customerCellphone'
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          CANCELLED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'rejectedName'
              }
            }
          },
          CANCEL_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'rejectedComment'
              }
            }
          },
          REJECTED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['rejectedStartDate', 'rejectedEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      },
      accident: {
        visible: false,
        imgSrc: '',
        query: {
          data: {
            status: 'PD'
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/accident',
            summary: 'GET /enocloud/query/service/accident/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.accident.query.data
            }
          }
        },
        accidentBook: {
          click(row: EnocloudQueryDefinitions['ServiceAccidentSettlementQueryDto']) {
            this.accident.accidentBook.imgSrc = row.accidentBookImgUrl
            this.accident.accidentBook.visible = true
          }
        },
        click(row: EnocloudQueryDefinitions['ServiceAccidentSettlementQueryDto']) {
          this.accident.imgSrc = row.damageOrderImgUrl
          this.accident.visible = true
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'service.vehicle.fuelCategory',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceSerialNo'
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CLAIM_COMPANY: {
            header: {
              filter: {
                type: 'select',
                field: 'claimsCompanies',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['SASCMC'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CONTACT_CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },
          VEHICLE_VIN: {
            header: {
              filter: {
                type: 'text',
                field: 'vin'
              }
            }
          },
          CLAIM_DOCUMENT: {
            header: {
              filter: {
                type: 'select',
                field: 'claimDocument',
                props: {
                  options: [
                    { message: '有', code: 'Y' },
                    { message: '无', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryIds',
                props: {
                  ajax: { action: 'GET /enocloud/common/service/category' },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settleStartDate', 'settleEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMPLETE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['completionStartDate', 'completionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          CLAIM_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          RISK_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['riskStartDate', 'riskEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          RISK_SPOT: {
            header: {
              filter: {
                type: 'text',
                field: 'riskSpot'
              }
            }
          },
          RISK_DRIVER: {
            header: {
              filter: {
                type: 'text',
                field: 'riskDriver'
              }
            }
          },
          PROVE: {
            header: {
              filter: {
                type: 'select',
                field: 'prove',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['ACSTPRV'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          RISK_SETTLING_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlingStartDate', 'settlingEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          RISK_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'type',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['ACSTDTTP'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          DIRECT_RISK: {
            header: {
              filter: {
                type: 'select',
                field: 'direct',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLAG'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          RISK_SETTLED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'settlingPerson'
              }
            }
          },
          RISK_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleComment'
              }
            }
          },
          CLAIM_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'accidentComment'
              }
            }
          },

          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'service.settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceComment'
              }
            }
          },
          SETTLEMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementComment'
              }
            }
          },

          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      },
      fake: {
        query: {
          data: {
            preparedEndDate: '',
            preparedStartDate: '',
            branchIds: [],
            fakeCode: 'Y'
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/service/fake',
            summary: 'GET /enocloud/query/service/fake/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.fake.query.data
            }
          }
        },
        img: {
          click(row: EnocloudQueryDefinitions['ServiceQueryDto']) {
            this.fake.imgSrc = row.serviceVehicleImgUrls
            this.fake.visible = true
          }
        },
        config: {
          SERVICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'vehicle.fuelCategory',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          CUSTOMER_SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'customer.salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['CLKTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          CUSTOMER_SALESMAN_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'customer.salesmanName'
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                multiple: true,
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          VEHICLE_VIN: {
            header: {
              filter: {
                type: 'text',
                field: 'vin'
              }
            }
          },

          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/service/category' },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ]
                }
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          MODIFIED_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['modifiedStartDate', 'modifiedEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgress',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['STLMTPRG'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SENDER: {
            header: {
              filter: {
                type: 'text',
                field: 'sender'
              }
            }
          },
          SENDER_TELEPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'senderTelephone'
              }
            }
          },
          SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['CLKTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SALESMAN_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'salesmanName'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          SETTLEMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementComment'
              }
            }
          },
          CUSTOMER_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.amount.get()
    this.maintenance.get()
    this.goods.get()
    this.internal.get()
    this.external.get()
    this.other.get()
    this.cancel.get()
    this.accident.get()
    this.fake.get()
    console.log(this.amount.query.advisorId,'===')

  }
})
</script>
